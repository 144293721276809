<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">{{ wedding.man.surname }} & {{ wedding.woman.surname }} </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio ref="audio" :file="music.file" :loop="music.loop" :autoplay="music.autoplay"/>
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro"/>

    <div class="bg-white agw-content" :style="{ 'margin-top' : `${$root.hero.height}px` }">
      <AGWIntro id="intro"/>

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan"/>
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes"/>
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="pad-y-60"/>
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.5358496830304!2d115.13354731478516!3d-8.82959899365931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8144b6fb9bbeb1f6!2zOMKwNDknNDYuNiJTIDExNcKwMDgnMDguNyJF!5e0!3m2!1sid!2sid!4v1645883586118!5m2!1sid!2sid"
        directionUrl="https://www.google.com/maps/dir//-8.829599,115.135736/@-8.829599,115.1335473,17z?hl=id-ID"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ 'height' : `${$root.dividerHeight}px` }"></div>

    <Footer />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height"/>
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 = 'https://ik.imagekit.io/crk/adek-suja/IMG_4797.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1645882210982&tr=w-1024'
const ImageHero2 = 'https://ik.imagekit.io/crk/adek-suja/IMG_4802.JPG?updatedAt=1645883066327&tr=w-533,h-797,fo-custom,cm-extract'
const ImageHero3 = 'https://ik.imagekit.io/crk/adek-suja/IMG_4793.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1645882209746&tr=w-1024'

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from '@/components/Himbauan.vue';
import Attendance from "@/components/Attendance";
import Footer from "@/components/Footer/Remind.vue";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    Footer,
  },
  data() {
    return {
      title: 'Pawiwahan',
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: 'I Made Darma Adiwinata',
          surname: 'Dek Adek',
          parents: 'I Made Nurata & Anak Agung Rai Agung Darmawati ',
          description: 'Putra kedua',
          address: 'Br. Dinas Tengah, Ds. Pecatu, Kec. Kuta Selatan, Kab. Badung - Bali ',
          photo: 'https://ik.imagekit.io/crk/adek-suja/IMG_4795.JPG?updatedAt=1645882720134&tr=w-572,h-717,fo-custom,cm-extract',
        },
        woman: {
          name: 'Ni Putu Sujayanti Krisna Dewi, S.E ',
          surname: 'Suja',
          parents: 'I Made Sukur & Ni Made Serti',
          address: 'Br. Dinas Tengah, DS. Pecatu, Kec. Kuta Selatan, Kab. Badung - Bali',
          description: 'Putri pertama',
          photo: 'https://ik.imagekit.io/crk/adek-suja/IMG_4794.JPG?updatedAt=1645882694191&tr=w-664,h-831,fo-custom,cm-extract',
        },
      },
      location: "Jl. Raya Uluwatu, Pecatu, Kuta Selatan, Pecatu, Kec. Kuta Selatan",
      time: {
        start: this.$moment("2022-03-11 15:00"),
        end: "Selesai"
      },
      mepandes: [
        {
          name: 'Putu Nanda Mas Rusteana Devi, S.Ak',
          image: 'https://ik.imagekit.io/crk/adek-suja/IMG_4752.JPG?updatedAt=1645884550410&tr=w-308,h-409,fo-custom,cm-extract',
          description: 'Putri dari',
          parents: ' I Made Gede Ratnata & Ni Luh Kade Yuliariati'
        },
        {
          name: 'I Gede Bagus Suteja ',
          image: 'https://ik.imagekit.io/crk/adek-suja/IMG_4753.JPG?updatedAt=1645884601077&tr=w-438,h-581,fo-custom,cm-extract',
          description: 'Putra dari',
          parents: ' I Made Gede Ratnata & Ni Luh Kade Yuliariati'
        },
        {
          name: 'Ni Putu Pradina Mas Jaya Ningrum, S.E',
          image: 'https://ik.imagekit.io/crk/adek-suja/pradina_sMD7ryHiT?ik-sdk-version=javascript-1.4.3&updatedAt=1645884393769',
          description: 'Putri dari',
          parents: 'I Putu Widiarta & Ni Wayan Kariasih'
        },
        {
          name: 'I Kadek Priangga Mas Dwi Putra',
          image: 'https://ik.imagekit.io/crk/adek-suja/priangga_cMKokS9Lc?ik-sdk-version=javascript-1.4.3&updatedAt=1645884436721',
          description: 'Putra dari',
          parents: 'I Putu Widiarta & Ni Wayan Kariasih'
        },
        {
          name: 'Luh Sri Ulandari Oktiari',
          image: 'https://ik.imagekit.io/crk/adek-suja/Frame_9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1645885675163',
          description: 'Putri dari',
          parents: 'I Wayan Budayada & Ni Komang Suartini '
        },
        {
          name: 'I Kadek Bintang Adi Oktaviana Putra',
          image: 'https://ik.imagekit.io/crk/adek-suja/Frame_10.png?ik-sdk-version=javascript-1.4.3&updatedAt=1645885675127',
          description: 'Putra dari',
          parents: 'I Wayan Budayada & Ni Komang Suartini '
        },
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
